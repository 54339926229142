.progress-div {
  position: absolute;
  bottom: 0;
  background-color: black;
  width: 100vw;
}

.progress {
  background-image: (
    $highlight-texture,
    linear-gradient(
      rgba($main-background, 0.5),
      rgba($main-background, 1),
      rgba($main-background, 0.5)
    )
  );
  height: 1vh;
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100vw;
  }
}
