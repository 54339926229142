@import "src/components/NavBar/navBar.scss";
@import "src/components/Gallery/gallery.scss";
@import "src/components/AboutSection/aboutSection.scss";
@import "src/components/EventsSection/eventsSection.scss";
@import "src/components/ProgramsSection/programsSection.scss";
@import "src/components/Footer/footer.scss";

.home {
  color: $main-primary;

  &:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ($main-texture, $highlight-background);
  }

  p {
    font-weight: 600;
  }

  a {
    color: $main-link;
  }

  a:hover {
    color: $main-hover;
  }

  .highlight {
    color: $main-highlight;
  }

  .header {
    display: flex;
    justify-content: center;

    .title {
      text-align: center;
      padding: $spacing-smallest $spacing-large;
      background: (
        $highlight-texture,
        linear-gradient(
          to right,
          rgba($highlight-background, 0),
          $highlight-background,
          rgba($highlight-background, 0)
        )
      );
    }
  }
}

// On mobile, disable horizontal scrolling
@media only screen and (max-width: $mobile-width-threshold) {
  .home {
    overflow-x: hidden;
  }
}
