.about-container {
  position: relative;
  margin-top: -$navbar-height;
  background: linear-gradient(rgba($main-background, 0), $main-background);
  padding: $navbar-height $spacing-smallest $spacing-smallest;

  .about {
    text-align: center;
    max-width: $main-container-width;
    margin: auto;

    .welcome {
      margin: auto;
      padding: $spacing-large calc($spacing-smallest/2);
      max-width: $main-container-width * 0.75;
    }

    .paragraph {
      margin: $spacing-smaller auto;
      padding: 0 calc($spacing-smallest/2);
    }

    .thanks {
      margin: $spacing-small auto 0;
      max-width: $main-container-width * 0.8;
      font-size: large;
    }

    .about-dns-bios {
      .about-dns-bio-name {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: larger;
        font-weight: 700;

        .about-dns-bio-links {
          padding-left: calc($spacing-smallest/2);
          .svg-inline--fa {
            width: $spacing-smaller;
            margin: auto;
          }
        }
      }

      .about-dns-bio-description {
        display: block;
        text-align: justify;
        font-size: medium;
      }

      h3:last-child {
        .about-dns-bio-name {
          flex-direction: row-reverse;
          text-align: right;

          .about-dns-bio-links {
            padding-left: 0;
            padding-right: calc($spacing-smallest/2);
          }
        }
      }
    }
  }
}

// On desktop, have bios be on either side of a center circular image
@media only screen and (min-width: $mobile-width-threshold + 1) {
  .about-dns {
    img {
      display: none;
    }

    .about-dns-names {
      display: flex;
      justify-content: space-between;
    }

    .about-dns-bios {
      --R: 120px; // radius of circular image
      --m: 1.5rem; // margin between image and paragraphs

      h3 {
        width: 50%;
        margin: 0;
        padding: 0 var(--m) 0 0;
        display: inline-block;
        vertical-align: top;
      }

      h3:last-child {
        padding: 0 0 0 var(--m);
      }

      h3:before {
        content: "";
        width: var(--R);
        height: calc(2 * var(--R));
        float: right;
        padding: var(--m) 0 var(--m) var(--m);
        margin-right: calc(-1 * var(--m));
        background: url("../media/dns/kawaii.png") content-box;
        background-size: 200% 100%;
        border-radius: var(--R) 0 0 var(--R);
        shape-outside: circle(
          var(--R) at var(--d, right) calc(-1 * var(--m)) top 50%
        );
      }

      h3:last-child:before {
        float: left;
        padding: var(--m) var(--m) var(--m) 0;
        margin-left: calc(-1 * var(--m));
        margin-right: 0;
        background-position: right;
        border-radius: 0 500px 500px 0;
        --d: left;
      }

      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }
    }
  }
}

// On mobile:
// 1. Reduce vertical spacing
// 2. Show circular image, then show bios below with smaller font.
@media only screen and (max-width: $mobile-width-threshold) {
  .about-container {
    padding-bottom: 0;
    background: linear-gradient(
      rgba($main-background, 0),
      $main-background 50%,
      $main-background
    );

    .about {
      .welcome {
        padding-bottom: $spacing-small;
      }

      .paragraph {
        margin-bottom: 0;
      }
    }
  }

  .about-dns {
    img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }

    .about-dns-bio {
      margin: $spacing-smallest auto;

      .highlight {
        background: (
          $highlight-texture,
          radial-gradient(
            $highlight-background,
            rgba($highlight-background, 0.2)
          )
        );
      }
    }
  }
}
