.eventsSection {
  margin: auto;
  padding: ($navbar-height + $spacing-smallest) 0 0;
  background: $main-background;

  h1 {
    text-align: center;
  }

  .event-card {
    max-width: $main-container-width * 0.8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: $spacing-smallest 0;
  }

  .event-header {
    margin-bottom: $spacing-smallest;
    color: $main-highlight;
  }

  .event-description {
    max-width: $main-container-width * 0.55;
    margin: auto;
    padding: 0 $spacing-smallest;

    p {
      text-align: justify;
    }
  }

  .thumbnail {
    max-width: $main-container-width * 0.25;
    margin: auto;
    padding: calc($spacing-smallest/2) $spacing-smallest;
  }
}

// On mobile, highlight program card when covering center of screen
@media only screen and (max-width: $mobile-width-threshold) {
  .eventsSection {
    .thumbnail {
      max-width: $main-container-width * 0.35;
      width: 100%;
    }
  }
}
