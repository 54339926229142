$card-width-ratio: 0.8;
$thumbnail-width-ratio: 0.35;
$description-width-ratio: 0.45;

.programs {
  margin: auto;
  padding: ($navbar-height + $spacing-smallest) 0 0;
  background: $main-background;

  h1 {
    text-align: center;
  }

  .paragraph {
    text-align: center;
    max-width: $main-container-width;
    margin: $spacing-smaller auto;
    padding: 0 $spacing-smallest;
    font-size: large;
  }
}

.program-cards ol {
  list-style-type: none;
  margin: auto;

  .program-card {
    max-width: $main-container-width * $card-width-ratio;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: $spacing-smallest 0;
    opacity: 0.8;
    transition: all 0.25s ease-in;

    .thumbnail {
      max-width: $main-container-width * $thumbnail-width-ratio;
      margin: auto;
      padding: calc($spacing-smallest/2) $spacing-smallest;
    }

    .program-description {
      max-width: $main-container-width * $description-width-ratio;
      margin: auto;
      padding: 0 $spacing-smallest;
      text-align: justify;
      color: $main-primary;
      z-index: 1;

      .program-header {
        text-align: left;
        margin-bottom: $spacing-smallest;
        color: $main-highlight;
      }

      .program-link {
        margin-top: $spacing-smallest;
        color: $main-link;
        .fa-book-open {
          display: none;
        }
      }
    }
  }

  li:nth-child(2n) {
    .program-card {
      flex-direction: row-reverse;

      .program-header,
      .program-link {
        text-align: right;
      }
    }
  }
}

// On desktop, highlight program card on hover
@media only screen and (min-width: $mobile-width-threshold + 1) {
  .program-cards {
    li {
      position: relative;
    }

    li:hover {
      .program-card {
        opacity: 1;
        cursor: pointer;

        .program-link:hover {
          font-style: italic;
          .fa-book {
            display: none;
          }
          .fa-book-open {
            display: inline;
          }
        }
      }
    }

    li:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: (
        $highlight-texture,
        radial-gradient($highlight-background, rgba($highlight-background, 0))
      );
      opacity: 0;
      transition: all 0.25s ease-in;
    }

    li:hover:before {
      opacity: 1;
    }
  }
}

// On mobile, highlight program card when covering center of screen
@media only screen and (max-width: $mobile-width-threshold) {
  .program-cards {
    li.selected {
      position: relative;

      .program-card {
        opacity: 1;

        .program-link {
          font-style: italic;
          .fa-book {
            display: none;
          }
          .fa-book-open {
            display: inline;
          }
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: (
          $highlight-texture,
          radial-gradient($highlight-background, rgba($highlight-background, 0))
        );
        opacity: 1;
        transition: all 0.25s ease-in;
      }
    }
  }
}
