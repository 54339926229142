// Use https://coolors.co to visualize color themes.

/*** HOME PAGE ***/

// Home page colors
$purple-amaranth: #b6244f;
$gray-independence: #3b3f54;
$white-snow: #fcf9f7;
$blue-light-steel: #b5becf;
$blue-gray: #7294c0;

// Home page color theme
$main-highlight: $purple-amaranth;
$main-primary: $gray-independence;
$main-background: rgba($white-snow, 0.9);
$main-hover: $blue-light-steel;
$main-link: $blue-gray;
$main-texture: url("../media/textures/ice-skated.png");
$highlight-background: rgba($main-link, 0.6);
$highlight-texture: url("../media/textures/fresh-snow.png");

/*** WIKIS ***/

// Wiki additional colors
$black-eerie: #131313;
$black-raisin: #191b24;
$black-rich: #060606;
$black-smokey: #1a130f;
$blue-azure: #5180e1;
$blue-baby-eyes: #94c0f0;
$blue-cosmic-cobalt: #3a2f8c;
$blue-shadow: #6b7f9e;
$blue-space-cadet: #232d43;
$blue-steel: #487fbc;
$blue-true: #466aab;
$bronze: #c7813f;
$brown-coyote: #7f6243;
$cyan-dark: #03888d;
$gold-jonquil: #e2be13;
$gray-cool: #909db6;
$green-feldgrau: #406458;
$lavendar-gray: #bec0cc;
$lilac-luster: #ac97af;
$opal: #adc8ca;
$orange-dark: #ff890a;
$pink-paradise: #e8485d;
$purpureus: #9746a7;
$red-racing: #cc0000; // aka Rosso Corsa
$white-alice-blue: #edf3fd;
$white-magnolia-blue: #ecf1fe;
$white-magnolia-purple: #efeefb;
$white-anti-flash: #ebebeb;
$white-platinum: #e4e3dd;

// Wiki themes

$friend-a-theme: (
  "highlight-color": $pink-paradise,
  "primary-color": $black-rich,
  "background-color": $white-snow,
  "hover-color": $blue-light-steel,
  "link-color": $blue-steel,
  "background-image": url("https://animeonice.com/cover-arts/friend-a.jpg"),
  "background-focus-position": 50% 65%,
);

$unravel-theme: (
  "highlight-color": $red-racing,
  "primary-color": $blue-light-steel,
  "background-color": $black-eerie,
  "hover-color": $blue-gray,
  "link-color": $white-anti-flash,
  "background-image": url("https://animeonice.com/cover-arts/unravel.jpg"),
  "background-filter": contrast(125%),
  "background-focus-position": 48% 50%,
);

$history-makers-theme: (
  "highlight-color": $cyan-dark,
  "primary-color": $black-rich,
  "background-color": $white-magnolia-blue,
  "hover-color": $blue-gray,
  "link-color": $blue-cosmic-cobalt,
  "background-image":
    url("https://animeonice.com/cover-arts/history-makers.jpeg"),
  "background-focus-position": 70% 65%,
  "background-focus-position-mobile": 21% 65%,
);

$nirvana-theme: (
  "highlight-color": $purpureus,
  "primary-color": $black-smokey,
  "background-color": $white-magnolia-purple,
  "hover-color": $blue-baby-eyes,
  "link-color": $blue-true,
  "background-image": url("https://animeonice.com/cover-arts/nirvana.jpg"),
  "background-focus-position": 50% 12%,
);

$gate-of-steiner-theme: (
  "highlight-color": $gold-jonquil,
  "primary-color": $white-anti-flash,
  "background-color": $blue-space-cadet,
  "hover-color": $lilac-luster,
  "link-color": $lavendar-gray,
  "background-image":
    url("https://animeonice.com/cover-arts/gate-of-steiner.png"),
  "background-focus-position": 50% 15%,
);

$moral-impasse-theme: (
  "highlight-color": $cyan-dark,
  "primary-color": $opal,
  "background-color": $black-eerie,
  "hover-color": $blue-gray,
  "link-color": $white-anti-flash,
  "background-image": url("https://animeonice.com/cover-arts/moral-impasse.png"),
  "background-focus-position": 50% 10%,
);

$moments-theme: (
  "highlight-color": $bronze,
  "primary-color": $black-raisin,
  "background-color": $white-alice-blue,
  "hover-color": $gray-cool,
  "link-color": $blue-azure,
  "background-image": url("https://animeonice.com/cover-arts/moments.jpg"),
  "background-focus-position": 37% 50%,
);

$barricades-theme: (
  "highlight-color": $green-feldgrau,
  "primary-color": $black-rich,
  "background-color": $white-platinum,
  "hover-color": $orange-dark,
  "link-color": $brown-coyote,
  "background-image": url("https://animeonice.com/cover-arts/barricades.jpg"),
  "background-focus-position": 30% 25%,
);

// $one-step-closer-theme: (
//   "highlight-color": $red-carmine,
//   "primary-color": $gray-independence,
//   "background-color": $white-snow,
//   "hover-color": $blue-light-steel,
//   "link-color": $blue-little-boy,
//   "background-image": url("../media/cover-arts/one-step-closer.png"),
//   "background-focus-position": 20% 90%,
// );
