@use "sass:map";

// For setting wiki metadata table odd rows' color
@function set-color-1($color) {
  @if (lightness($color) > 50) {
    @return darken($color, 10%);
  } @else {
    @return lighten($color, 10%);
  }
}

// For setting wiki metadata table even rows' color
@function set-color-2($color) {
  @if (lightness($color) > 50) {
    @return darken($color, 20%);
  } @else {
    @return lighten($color, 20%);
  }
}

$themes: (
  "friend-a": $friend-a-theme,
  "unravel": $unravel-theme,
  "history-makers": $history-makers-theme,
  "nirvana": $nirvana-theme,
  "gate-of-steiner": $gate-of-steiner-theme,
  "moral-impasse": $moral-impasse-theme,
  "moments": $moments-theme,
  "barricades": $barricades-theme,
  // "one-step-closer": $one-step-closer-theme,
);

$wiki-end-fade-height: 20vh;
$wiki-background-opacity: 0.9;
$wiki-header-opacity: 0.95;

$wiki-header-height-desktop: 120px;
$wiki-header-height-mobile: 80px;

@mixin theme(
  $name,
  $highlight-color,
  $primary-color,
  $background-color,
  $hover-color,
  $link-color,
  $background-image,
  $background-filter,
  $background-focus-position,
  $background-focus-position-mobile
) {
  .#{$name} {
    color: $primary-color;
    background: linear-gradient(
      to top,
      rgba($background-color, 0),
      rgba($background-color, 0) 100vh,
      rgba($background-color, $wiki-background-opacity) 100vh +
        $wiki-end-fade-height,
      rgba($background-color, $wiki-background-opacity)
    );

    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-image: $background-image;
      filter: $background-filter;
      background-position: $background-focus-position;
      background-size: cover;
      z-index: -1;
    }

    .highlight,
    h2,
    h3 {
      color: $highlight-color;
    }

    .highlight-background {
      color: $background-color;
      background-color: $highlight-color;
    }

    a {
      color: $link-color;
      text-decoration: none;
      cursor: pointer;
    }

    hr {
      margin: $spacing-smallest 0;
    }

    img {
      max-width: 100%;
      max-height: 480px;
    }

    img[src*="half"] {
      max-width: 48%;
    }

    img[src*="third"] {
      max-width: 31%;
    }

    img[src*="right"] {
      margin-left: 1%;
    }

    .wiki-header-container {
      position: sticky;
      top: 0;
      padding: $spacing-smallest;
      display: flex;
      align-self: flex-start;
      z-index: 10;
      background: rgba($background-color, $wiki-header-opacity);
      box-shadow: 0px 2px 5px 5px rgba(black, 0.5);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $main-texture;
        filter: invert(0.2);
        opacity: 0.5;
        z-index: -1;
      }

      &.desktop-only {
        .wiki-header {
          width: $mobile-width-threshold;
        }

        .logo {
          background: ($highlight-texture, $blue-shadow);
        }

        .title {
          color: $link-color;
          cursor: pointer;
        }

        .subtitle {
          font-style: italic;
        }

        .socials {
          letter-spacing: calc($spacing-smallest/2);
        }
      }

      &.mobile-only {
        display: flex;
        flex-direction: column;
        height: $spacing-medium;

        .wiki-header {
          margin: 0;
        }

        .wiki-toc-container {
          line-height: 1.5;
          display: none;

          .socials {
            font-size: x-large;
            letter-spacing: $spacing-smallest;
            text-align: center;
            margin-top: $spacing-smallest;
          }

          .wiki-toc-logo {
            margin: auto;
            text-align: center;
          }
        }

        .title {
          color: $primary-color;
        }

        .toc-open-icon {
          display: none;
        }

        &.toc-open {
          .toc-open-icon {
            display: block;
          }
          .toc-closed-icon {
            display: none;
          }

          .wiki-toc-container {
            display: block;
            position: absolute;
            top: $spacing-medium;
            right: -$spacing-smaller;
            padding: $spacing-smallest;
            width: 70%;
            background: rgba($background-color, $wiki-header-opacity);
            box-shadow: -2px 2px 5px rgba(black, 0.5);
          }
        }
      }

      .wiki-header {
        display: flex;
        margin: auto;
        align-items: center;
        line-height: 1.5;

        .wiki-logo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: right;
          padding: 0 $spacing-smallest;
          flex: 1 0 auto;
        }

        .wiki-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 $spacing-smallest;
          flex: 5 1 auto;
        }
      }
    }

    .wiki-columns {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      justify-content: center;
      margin: $spacing-smaller auto 0;
      padding-bottom: $wiki-end-fade-height;
      max-width: $main-container-width;

      .wiki-overview {
        max-width: $main-container-width * 0.3;

        .thumbnail {
          margin-bottom: calc($spacing-smallest/2);
        }

        .table {
          a {
            color: $primary-color;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .wiki-main {
        position: relative;
        flex: 3 1 $main-container-width * 0.5;
        margin: $spacing-smallest;
      }
    }

    .wiki-metadata {
      margin: $spacing-smallest;
      font-size: small;

      .name {
        color: $background-color;
        background: $highlight-color;
        padding: 5px;
        margin-bottom: 0;
        text-align: center;
      }

      .table {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          padding: 5px;

          .key {
            min-width: 110px;
            width: 110px;
          }

          .value {
            white-space: pre-wrap;
          }

          .theme:first-letter {
            text-transform: capitalize;
          }
        }

        li:nth-child(2n + 1) {
          background: set-color-1($background-color);
        }

        li:nth-child(2n) {
          background: set-color-2($background-color);
        }
      }
    }

    .wiki-toc-container {
      position: sticky;
      top: $spacing-largest + $spacing-smaller;
      margin: 0 $spacing-smaller;
      line-height: 2;

      .is-active-link {
        color: $highlight-color;
        &:before {
          content: "> ";
        }
      }

      ol {
        list-style-type: none;
        margin-bottom: $spacing-smallest;

        li {
          text-transform: uppercase;
          margin-top: $spacing-smallest;

          ol li {
            margin-left: $spacing-smallest;
            text-transform: none;
            margin-top: 0;
          }
        }
      }
    }

    .wiki-toc-container.desktop-only {
      padding-bottom: 2 * $spacing-large;
    }

    .wiki-blurb {
      display: flex;
      opacity: 0.8;

      p,
      .blurb-quote-right {
        margin-left: $spacing-smallest;
      }

      .blurb-quote-right {
        align-self: flex-end;
      }
    }

    .wiki-video {
      margin: $spacing-smallest auto;
    }

    .wiki-published {
      text-align: center;
      line-height: 1.5;
    }

    .wiki-content {
      h2,
      h3 {
        &:focus {
          outline: none;
        }
      }

      h2 {
        text-transform: uppercase;
        margin: $spacing-small 0 $spacing-smallest;
      }

      h3 {
        margin: $spacing-smaller 0 $spacing-smallest;
      }

      p {
        margin: $spacing-smallest 0;
      }

      li {
        margin-left: $spacing-smaller;
      }

      blockquote {
        margin-left: $spacing-smaller;
        font-style: italic;
      }

      a {
        text-decoration: underline;
      }
    }

    .wiki-nav {
      display: flex;
      margin-top: $spacing-medium;

      a,
      a:hover {
        color: $background-color;
      }

      .wiki-nav-prev,
      .wiki-nav-next {
        max-width: 48%;
        display: flex;
        align-items: center;
        border-radius: $spacing-smallest;
        background-color: $link-color;

        h3 {
          color: $background-color;
        }
      }

      .wiki-nav-prev {
        h1 {
          margin-left: calc($spacing-smallest/2);
        }
      }

      .wiki-nav-next {
        margin-left: auto;
        text-align: right;

        h1 {
          margin-right: calc($spacing-smallest/2);
        }
      }
    }

    .wiki-cover-art {
      height: 100vh;
      display: flex;
      align-items: flex-end;

      .wiki-credits {
        text-align: center;
        margin: 0 auto;
        padding-top: $spacing-small;
        padding-bottom: $spacing-smallest;
        width: 100%;
        background: linear-gradient(
          rgba($background-color, 0),
          rgba($background-color, 0.6) $spacing-small,
          rgba($background-color, 0.8)
        );

        p {
          margin-top: calc($spacing-smallest/2);
        }
      }
    }

    @media only screen and (min-width: $mobile-width-threshold + 1) {
      .mobile-only {
        display: none !important;
      }

      :target {
        scroll-margin-top: $wiki-header-height-desktop;
      }

      a:hover {
        color: $hover-color;
      }

      .wiki-metadata {
        margin-bottom: $spacing-smaller;
      }

      .wiki-blurb {
        margin: 0 $spacing-smaller;
      }

      .wiki-nav-prev,
      .wiki-nav-next {
        opacity: 0.8;
        transition: all 0.1s ease-in;

        .wiki-nav-text {
          margin: 0 $spacing-smallest;
        }

        .image {
          width: auto;
          height: $spacing-medium;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    @media only screen and (max-width: $mobile-width-threshold) {
      .desktop-only {
        display: none !important;
      }

      :target {
        scroll-margin-top: $wiki-header-height-mobile;
      }

      &:before {
        @if variable-exists(background-focus-position-mobile) {
          background-position: $background-focus-position-mobile;
        } @else {
          background-position: $background-focus-position;
        }
      }

      .wiki-blurb {
        margin: 0 $spacing-smallest;
      }

      .wiki-nav-prev,
      .wiki-nav-next {
        h1 {
          font-size: large;
        }

        .wiki-nav-text {
          font-size: small;
          margin: calc($spacing-smallest/2);
        }

        .image {
          display: none;
        }
      }

      .wiki-nav-prev {
        .wiki-nav-text {
          margin-right: $spacing-smallest;
        }
      }

      .wiki-nav-next {
        .wiki-nav-text {
          margin-left: $spacing-smallest;
        }
      }

      .wiki-credits {
        font-size: small;
      }
    }
  }
}

@each $theme-name, $theme in $themes {
  @include theme(
    $theme-name,
    map.get($theme, "highlight-color"),
    map.get($theme, "primary-color"),
    map.get($theme, "background-color"),
    map.get($theme, "hover-color"),
    map.get($theme, "link-color"),
    map.get($theme, "background-image"),
    map.get($theme, "background-filter"),
    map.get($theme, "background-focus-position"),
    map.get($theme, "background-focus-position-mobile")
  );
}
