$num-navbar-items: 5;

.navbar {
  background: ($highlight-texture, rgba($main-hover, 0.8));
  box-shadow: 0px 2px 5px 5px rgba(black, 0.5);
  min-height: $navbar-height;
  max-height: $navbar-height;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  overflow: hidden;

  a:hover {
    cursor: pointer;
  }

  .nav-list {
    list-style-type: none;

    .nav-section {
      float: left;
      padding: $spacing-smallest;

      .nav-section-label {
        font-size: x-large;
      }
    }

    .nav-section.nav-selected {
      background: ($highlight-texture, rgba($main-background, 0.3));
    }
  }

  .mobile-only {
    display: none;
  }
}

// On mobile, make navbar a dropdown menu that can open and close
@media only screen and (max-width: $mobile-width-threshold) {
  .navbar {
    position: fixed;
    justify-content: space-between;
    align-items: flex-start;
    transition: max-height 0.25s ease-in;
    background: ($highlight-texture, rgba($main-hover, 0.9));

    .mobile-only {
      display: block;
    }

    .nav-title {
      padding: $spacing-smallest;
      h2 {
        color: $main-highlight;
      }
    }

    .navbar-menu {
      padding: $spacing-smallest;

      a:hover {
        color: $main-link;
      }

      .navbar-opened {
        display: none;
      }
    }

    .nav-list {
      display: flex;
      flex-direction: column;
      list-style-type: none;

      .nav-section {
        padding: calc($spacing-smallest/2) $spacing-smallest;
      }

      .nav-section.mobile-disabled {
        .nav-section-link {
          display: none;
        }
      }
    }

    .socials {
      padding: $spacing-smallest;
      font-size: x-large;
      letter-spacing: $spacing-smallest;
    }
  }

  .navbar.nav-open {
    max-height: $navbar-height * ($num-navbar-items + 1);

    .navbar-menu {
      .navbar-closed {
        display: none;
      }
      .navbar-opened {
        display: block;
      }
    }
  }
}
