/*** SPACINGS ***/
$spacing-smallest: 1rem;
$spacing-smaller: 2rem;
$spacing-small: 3rem;
$spacing-medium: 4rem;
$spacing-large: 5rem;
$spacing-larger: 6rem;
$spacing-largest: 7rem;

/*** LENGTHS ***/
$main-container-width: 1200px;
$navbar-height: $spacing-medium;
$mobile-width-threshold: 992px;

/*** FONTS ***/
$font-primary: "Quicksand";
$font-highlight: "Quicksand";
