.footer {
  text-align: center;
  margin: auto;
  padding: ($navbar-height + $spacing-smallest) 0 $spacing-small;
  background: linear-gradient($main-background, rgba($main-background, 0));
  font-size: large;

  p {
    max-width: $main-container-width * 0.8;
    margin: $spacing-smallest auto;
    padding: 0 $spacing-smallest;
  }

  .socials {
    font-size: xx-large;
    letter-spacing: calc($spacing-smallest/2);
  }

  img {
    max-width: $main-container-width * 0.3;
    margin: $spacing-smallest auto;
    padding: 0 $spacing-smaller;
    filter: drop-shadow(
      $spacing-smallest $spacing-smallest calc($spacing-smallest/2)
        $main-primary
    );
  }

  .copyright {
    margin: $spacing-smallest auto 0;
    font-size: medium;
  }

  .credits {
    margin: calc($spacing-smallest/2) auto;
    font-size: small;

    .credits-snow {
      background: (
        $highlight-texture,
        radial-gradient($highlight-background, rgba($highlight-background, 0.2))
      );
    }
  }
}

// On mobile, make font and vertical spacing smaller.
@media only screen and (max-width: $mobile-width-threshold) {
  .footer {
    font-size: medium;
    padding-bottom: $spacing-smallest;
  }
}
