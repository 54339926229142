@import "./GalleryItem/galleryItem.scss";
@import "./GalleryLogo/galleryLogo.scss";
@import "./ProgressBar/progressBar.scss";

$arrow-container-width: 15vw;
$arrow-background-width-ratio: 33%;

.gallery {
  .carousel {
    .slide {
      padding: 0;
    }

    .control-prev.control-arrow:before,
    .control-next.control-arrow:before {
      transition: all 0.25s ease-out;
    }

    .control-arrow {
      width: $arrow-container-width;

      &.control-prev:before {
        float: left;
        border-right-color: $main-hover;
      }
      &.control-next:before {
        float: right;
        border-left-color: $main-hover;
      }

      &.control-prev:hover {
        background: linear-gradient(
          to right,
          rgba($highlight-background, 0.5),
          transparent $arrow-background-width-ratio,
          transparent
        );
      }
      &.control-next:hover {
        background: linear-gradient(
          to left,
          rgba($highlight-background, 0.5),
          transparent $arrow-background-width-ratio,
          transparent
        );
      }
    }
  }

  // Access specifically parent div of .thumbs-wrapper
  .carousel-root > div:nth-child(2) {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }

  .carousel .thumbs-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: $spacing-smallest;
    background: ($highlight-texture, rgba($main-hover, 0.8));
    transition: all 0.25s ease-out;
    opacity: 0;

    .thumb {
      border-radius: 5%;
      border-color: transparent;
    }

    .thumb:hover {
      border-color: $main-link;
      cursor: pointer;
    }

    .thumb.selected {
      border-color: $main-highlight;
      cursor: default;
    }
  }

  .thumbs-wrapper:hover {
    opacity: 1;
  }
}
