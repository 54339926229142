.gallery-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  transition: all 0.25s ease-in;
  padding: $spacing-smaller $spacing-small;

  .logo {
    text-align: center;
    font-size: 500%;
    width: 400px;
  }

  .down-arrow-container {
    margin-top: $spacing-smallest;
    text-align: center;

    .fa-circle-chevron-down {
      width: $spacing-small;
      height: $spacing-small;
      border-radius: 50%;
      color: $highlight-background;
      background: ($highlight-texture, $main-hover);
    }
  }
}

.gallery-logo:hover {
  cursor: pointer;
  opacity: 1;
}
