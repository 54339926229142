@import "./variables.scss";
@import "./themes.scss";

@import "../components/Home/home.scss";
@import "../components/Wiki/wiki.scss";
@import "../components/Logo/logo.scss";
@import "../components/Thumbnail/thumbnail.scss";

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-primary;

  p {
    font-family: $font-primary;
    font-weight: 500;
  }

  h1,
  h2,
  h3 {
    font-family: $font-highlight;
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  .no-wrap {
    white-space: nowrap;
  }
}

// On mobile, disable horizontal scrolling
@media only screen and (max-width: $mobile-width-threshold) {
  html {
    overflow-x: hidden;
  }
}
