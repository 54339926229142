$margin-spacing: 10%;
$blank-spacing: 30%;

.slide-div {
  .preview {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    opacity: 0.7;
  }

  .quote {
    position: absolute;
    font-style: italic;
    font-size: x-large;
    color: $main-background;
    opacity: 1;
    padding: $spacing-smallest;
  }

  .quote-anime {
    top: $margin-spacing;
  }

  .quote-personal {
    bottom: $margin-spacing;

    a {
      color: $main-background;

      &:hover {
        color: $main-background;
        text-decoration: underline;
      }
    }
  }

  .quote-left {
    text-align: left;
    left: $margin-spacing;
    margin-right: $blank-spacing;
  }

  .quote-right {
    text-align: right;
    right: $margin-spacing;
    margin-left: $blank-spacing;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
